import Incidents from './Incidents'
import Construction from './Construction'
import SpecialEvents from './SpecialEvents'
import Congestion from './Congestion'
import RealTimeTraffic from './RealTimeTraffic'
import TravelTimes from './TravelTimes';
import Cameras from './Cameras'
import DMS from './DMS'
import WeatherStations from './WeatherStations'
import WeatherWarnings from './WeatherWarnings'
import MileMarkers from './MileMarkers'
import Reports from './Reports'
import About from './About'
import Notices from './Notices'
import Shields from './Shields'
import Help from './Help'
import Trips from './Trips'
import JaneByrneCamera from './JaneByrneCamera'
import TransitAlerts from './TransitAlerts';
import Transit from './Transit';
import Truckers from './Truckers';
import TruckParking from './TruckParking';
import Ferry from './Ferry'

const definedModules = [
    Incidents,
    Construction,
    SpecialEvents,
    Congestion,
    RealTimeTraffic,
    TravelTimes,
    Cameras,
    DMS, 
    WeatherStations,
    WeatherWarnings,
    MileMarkers,
    Reports,
    Truckers,
    Notices,
    Trips,
    Transit,
    Help,
    About,
    Shields,
    JaneByrneCamera,
    TransitAlerts,
    TruckParking,
    Ferry

]

export default definedModules;