import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummaryReverse } from './Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';


const createAlertslink = (d) => {
    if (d.latitude !== 0 && d.longitude !== 0) {
        let url = `/map?id=${d.incidentId}&lat=${d.latitude}&lng=${d.longitude}&type=`
        if (d.text.includes("incident")) {
            url = url + "Incidents"
        } else if (d.text.includes("congestion")) {
            url = url + "Congestion"
        }
        if(null !== d.mapName) {
            url = url + `&mapName=${d.mapName}` 
        }
        d.link = url;
    }
    return d;
}

export default function Alerts(props) {
    const [alerts, setAlerts] = React.useState([]);
    const [error, setError] = React.useState("");

    useEffect(() => {
        const fetchApi = async () => {
            const res = await fetch(process.env.REACT_APP_API_HOST + "/alerts.json");
            res.json()
                .then(res => {
                    const decodedAlerts = res.map(alert => createAlertslink(alert))
                    setAlerts(decodedAlerts);
                })
        }
        fetchApi().catch(err => {
            setError(err)
        });
    }, []);

    if (alerts.length > 0) {
        return (
            <Accordion>
                <AccordionSummaryReverse
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="alert-content"
                    id="alert-header"
                    className="controls-header"
                >
                    <img src={process.env.PUBLIC_URL + '/mapicons/Alerts_v14.png'} alt='Alert' width={25} height={25} />
                    <Typography variant="subtitle3" sx={{ fontWeight: "bold", ml: 1 }}>Alerts ({alerts.length})</Typography>
                </AccordionSummaryReverse>
                <AccordionDetails sx={{ overflow: "auto", padding: 0, borderBottom: "1px solid rgb(192,192,192,0.3)" }}>
                    {
                        alerts.map((alert, idx) => {
                            return (
                                <Box className={idx % 2 === 0 ? "styledtablerow" : ""} key={idx} sx={{p:1}}>
                                    <Typography variant="body2">
                                        <span dangerouslySetInnerHTML={{ __html: alert.text }}></span>
                                        {undefined !== alert.link ?
                                        <span>&nbsp;(<Link to={alert.link} target="_blank" className="motdLink" title="Click for incident map">Location<LinkIcon fontSize="small" className="link-icon" /></Link>)</span>:null
                                    }
                                    </Typography>
                                </Box>

                            )
                        })
                    }
                </AccordionDetails>
            </Accordion>
        )
    } else if (error || alerts.length === 0) {
        return null;
    } else {
        return null;
    }
}