import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Popup,
  ZoomControl,
  MapContainer,
  GeoJSON,
  useMap,
  Polyline,
  FeatureGroup
} from "react-leaflet";
import { Polygon } from 'react-leaflet/Polygon';
import 'leaflet/dist/leaflet.css';
import L, { Control } from 'leaflet';
import { createControlComponent } from "@react-leaflet/core";
import modules from '../modules';
import { putValueToStorage, getValueFromStorage, useStateWithLocalStorage } from '../util/Storage'
//import DataCacheContext from '../context/DataCacheContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import MarkerClusterGroup from 'react-leaflet-cluster';
import _ from 'lodash'
import LayersContext from '../context/LayersContext';
import mapsJson from '../maps.json'
import outlineJson from '../outline.json'
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ColorBlindContext from '../context/ColorBlindContext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


(function (factory, window) {
  if (typeof exports === 'object') {
    module.exports = factory(require('leaflet'));
  }
  if (typeof window !== 'undefined' && window.L) {
    window.L.PolylineOffset = factory(L);
  }
}(function (L) {

  function forEachPair(list, callback) {
    if (!list || list.length < 1) { return; }
    for (var i = 1, l = list.length; i < l; i++) {
      callback(list[i - 1], list[i]);
    }
  }

  /**
  Find the coefficients (a,b) of a line of equation y = a.x + b,
  or the constant x for vertical lines
  Return null if there's no equation possible
  */
  function lineEquation(pt1, pt2) {
    if (pt1.x === pt2.x) {
      return pt1.y === pt2.y ? null : { x: pt1.x };
    }

    var a = (pt2.y - pt1.y) / (pt2.x - pt1.x);
    return {
      a: a,
      b: pt1.y - a * pt1.x,
    };
  }

  /**
  Return the intersection point of two lines defined by two points each
  Return null when there's no unique intersection
  */
  function intersection(l1a, l1b, l2a, l2b) {
    var line1 = lineEquation(l1a, l1b);
    var line2 = lineEquation(l2a, l2b);

    if (line1 === null || line2 === null) {
      return null;
    }

    if (line1.hasOwnProperty('x')) {
      return line2.hasOwnProperty('x')
        ? null
        : {
          x: line1.x,
          y: line2.a * line1.x + line2.b,
        };
    }
    if (line2.hasOwnProperty('x')) {
      return {
        x: line2.x,
        y: line1.a * line2.x + line1.b,
      };
    }

    if (line1.a === line2.a) {
      return null;
    }

    var x = (line2.b - line1.b) / (line1.a - line2.a);
    return {
      x: x,
      y: line1.a * x + line1.b,
    };
  }

  function translatePoint(pt, dist, heading) {
    return {
      x: pt.x + dist * Math.cos(heading),
      y: pt.y + dist * Math.sin(heading),
    };
  }

  //
  // JFD - Inlined PolylineOffset library code here to apply two bug fixes 
  //   1. floating-point coordinate values were being converted to ints before offsetting them
  //   2. polygons without a pathOptions object were causing an exception when an attempt to access pathOptions was made
  // See BUGFIX comments below.
  //
  var PolylineOffset = {
    offsetPointLine: function (points, distance) {
      var offsetSegments = [];

      forEachPair(points, L.bind(function (a, b) {
        if (a.x === b.x && a.y === b.y) { return; }

        // angles in (-PI, PI]
        var segmentAngle = Math.atan2(a.y - b.y, a.x - b.x);
        var offsetAngle = segmentAngle - Math.PI / 2;

        offsetSegments.push({
          offsetAngle: offsetAngle,
          original: [a, b],
          offset: [
            translatePoint(a, distance, offsetAngle),
            translatePoint(b, distance, offsetAngle)
          ]
        });
      }, this));

      return offsetSegments;
    },

    offsetPoints: function (pts, offset) {
      var offsetSegments = this.offsetPointLine(pts, offset);
      return this.joinLineSegments(offsetSegments, offset);
    },

    /**
    Join 2 line segments defined by 2 points each with a circular arc
    */
    joinSegments: function (s1, s2, offset) {
      // TODO: different join styles
      return this.circularArc(s1, s2, offset)
        .filter(function (x) { return x; })
    },

    joinLineSegments: function (segments, offset) {
      var joinedPoints = [];
      var first = segments[0];
      var last = segments[segments.length - 1];

      if (first && last) {
        joinedPoints.push(first.offset[0]);
        forEachPair(segments, L.bind(function (s1, s2) {
          joinedPoints = joinedPoints.concat(this.joinSegments(s1, s2, offset));
        }, this));
        joinedPoints.push(last.offset[1]);
      }

      return joinedPoints;
    },

    segmentAsVector: function (s) {
      return {
        x: s[1].x - s[0].x,
        y: s[1].y - s[0].y,
      };
    },

    getSignedAngle: function (s1, s2) {
      const a = this.segmentAsVector(s1);
      const b = this.segmentAsVector(s2);
      return Math.atan2(a.x * b.y - a.y * b.x, a.x * b.x + a.y * b.y);
    },

    /**
    Interpolates points between two offset segments in a circular form
    */
    circularArc: function (s1, s2, distance) {
      // if the segments are the same angle,
      // there should be a single join point
      if (s1.offsetAngle === s2.offsetAngle) {
        return [s1.offset[1]];
      }

      const signedAngle = this.getSignedAngle(s1.offset, s2.offset);
      // for inner angles, just find the offset segments intersection
      if ((signedAngle * distance > 0) &&
        (signedAngle * this.getSignedAngle(s1.offset, [s1.offset[0], s2.offset[1]]) > 0)) {
        return [intersection(s1.offset[0], s1.offset[1], s2.offset[0], s2.offset[1])];
      }

      // draws a circular arc with R = offset distance, C = original meeting point
      var points = [];
      var center = s1.original[1];
      // ensure angles go in the anti-clockwise direction
      var rightOffset = distance > 0;
      var startAngle = rightOffset ? s2.offsetAngle : s1.offsetAngle;
      var endAngle = rightOffset ? s1.offsetAngle : s2.offsetAngle;
      // and that the end angle is bigger than the start angle
      if (endAngle < startAngle) {
        endAngle += Math.PI * 2;
      }
      var step = Math.PI / 8;
      for (var alpha = startAngle; alpha < endAngle; alpha += step) {
        points.push(translatePoint(center, distance, alpha));
      }
      points.push(translatePoint(center, distance, endAngle));

      return rightOffset ? points.reverse() : points;
    }
  }

  // Modify the L.Polyline class by overwriting the projection function
  L.Polyline.include({
    _projectLatlngs: function (latlngs, result, projectedBounds) {
      var isFlat = latlngs.length > 0 && latlngs[0] instanceof L.LatLng;

      if (isFlat) {
        var ring = latlngs.map(L.bind(function (ll) {
          // BUGFIX - do not round coordinates to nearest integer before offsetting them
          var point = this._map.project(ll)._subtract(this._map.getPixelOrigin());
          // Old code called Leaflet's latLngLayerPoint() which rounds coordinates to nearest integer
          // var point = this._map.latLngToLayerPoint(ll);
          if (projectedBounds) {
            projectedBounds.extend(point);
          }
          return point;
        }, this));

        // Offset management hack ---
        // BUGFIX - Polygons do not have a pathOptions but this code is called by leaflet for them anyways
        if (this.options.pathOptions && this.options.pathOptions.offset) {
          ring = L.PolylineOffset.offsetPoints(ring, this.options.pathOptions.offset);
        }
        // Offset management hack END ---

        result.push(ring.map(function (xy) {
          return L.point(xy.x, xy.y);
        }));
      } else {
        latlngs.forEach(L.bind(function (ll) {
          this._projectLatlngs(ll, result, projectedBounds);
        }, this));
      }
    }
  });

  L.Polyline.include({
    setOffset: function (offset) {
      this.options.offset = offset;
      this.redraw();
      return this;
    }
  });

  return PolylineOffset;

}, window));



delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const decodeUnsignedIntegers = (encoded) => {
  var numbers = [];
  var current = 0;
  var shift = 0;
  for (var i = 0, len = encoded.length; i < len; ++i) {
    var b = encoded.charCodeAt(i) - 63;

    current |= (b & 0x1f) << shift;

    if (b < 0x20) {
      numbers.push(current);
      current = 0;
      shift = 0;
    } else {
      shift += 5;
    }
  }
  return numbers;
}

const decodeSignedIntegers = (encoded) => {
  var numbers = decodeUnsignedIntegers(encoded);
  for (var i = 0, len = numbers.length; i < len; ++i) {
    var num = numbers[i];
    numbers[i] = (num & 1) ? ~(num >> 1) : (num >> 1);
  }
  return numbers;
}

const decodeFloats = (encoded) => {
  var numbers = decodeSignedIntegers(encoded);
  for (var i = 0, len = numbers.length; i < len; ++i) {
    numbers[i] /= 100000;
  }

  return numbers;
}

const decodeDeltas = (encoded) => {
  var lastNumbers = [];
  var numbers = decodeFloats(encoded);
  for (var i = 0, len = numbers.length; i < len;) {
    for (var d = 0; d < 2; ++d, ++i) {
      //numbers[i] = Math.round((lastNumbers[d] = numbers[i] + (lastNumbers[d] || 0)) * 100000.0)/100000.0;
      numbers[i] = (lastNumbers[d] = numbers[i] + (lastNumbers[d] || 0));
    }
  }
  return numbers;
}

const decode = (encoded) => {
  var flatPoints = decodeDeltas(encoded);
  var points = [];
  for (var i = 0, len = flatPoints.length; i + 1 < len;) {
    var point = [];
    for (var dim = 0; dim < 2; ++dim) {
      point.push(flatPoints[i++]);
    }
    points.push(point);
  }
  return points;
}

// from encoded GeoJSON MultiLineString coordinates ['encoded path 1', 'encoded path 2', ...] to LeafLet LatLng[][] format
// [[{lat: lat11, lng: long11}, ...], [{lat: lat21, lng: long21}, ...], ...]
// if this is used for a Leaflet GeoJson layer, you need to reverse the coord set
// if this is used for explicitly drawing Leaflet polylines, do not reverse coord set
const getDecodedLatLngArrayArray = (coordinates) => {
  var multiPolyline = [];
  for (var ix = 0; ix < coordinates.length; ix++) {
    var polyline = decode(coordinates[ix]);
    multiPolyline.push(polyline);
  }
  return multiPolyline;
}

const getBoundsString = (bounds) => {
  let boundsString = bounds.toBBoxString();
  let boundsArray = boundsString.split(",");
  for (let i = 0; i < boundsArray.length; i++) {
    boundsArray[i] = boundsArray[i] * 1;
  }

  return { bbox: boundsArray };

}

const getGeoLineFullUpdate = async (bounds, endpoint, setGeoJson, currentIds, setIsUpdating) => {
  const requestObject = getBoundsString(bounds);
  requestObject.exclude = currentIds.current;
  endpoint = endpoint + "?type=encoded_lines"
  const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestObject)
  });
  res.json().then(res => {
    let featureCollection = res;
    let features = featureCollection.features;
    features.map(feature => {
      feature.geometry.coordinates = getDecodedLatLngArrayArray(feature.geometry.coordinates);
      return feature;
    });
    setGeoJson(geoJson => {
      if (geoJson) {
        let combinedfeatures = geoJson.features.concat(featureCollection.features);
        geoJson.features = combinedfeatures;
        return { ...geoJson };
      } else {
        return featureCollection;
      }
    });
    setIsUpdating(false);
  });
}


const getGeoLineColorUpdate = async (bounds, endpoint, setGeoJson, currentIds, setIsUpdating) => {
  const requestObject = getBoundsString(bounds);
  requestObject.ids = currentIds.current;
  endpoint = endpoint + "?type=update"
  const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestObject)
  });
  res.json().then(res => {
    let updates = res.updates;
    setGeoJson(geoJson => {
      let updateMap = new Map(updates.map(obj => [obj.id, obj.cng]));
      if (geoJson) {
        geoJson.features.forEach(feature => {
          feature.properties.cng = updateMap.get(feature.properties.id);
        })
      }
      return { ...geoJson }
    })
    setIsUpdating(false);
  });
}

const GeoJsonCongestionLayerOverlay = (props) => {
  const { module, setUpdate, setSelectedFeature, selectedFeature, setCurrentModule, update, setClickLatLng, setLayersOn, zoom, fullUpdate, setFullUpdate } = props;
  const layersContext = React.useContext(LayersContext);
  const [artVisible, setArtVisible] = useState(undefined !== layersContext["Arterial Congestion"] ? layersContext["Arterial Congestion"]?.on : true);
  const [hwyVisble, setHwyVisible] = useState(undefined !== layersContext["Highway Congestion"] ? layersContext["Highway Congestion"]?.on : true);
  const [zoomArtVisible, setZoomArtVisible] = useState(true);
  const [zoomHwyVisible, setZoomHwyVisible] = useState(true);
  const [layerRenderer, setLayerRender] = useState(null);
  const [geoJson, setGeoJson] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const currentIds = useRef([]);
  const map = useMap();
  const endpoint = module.endpoint;
  const mystyle = module.style;
  const maxZoomArt = module.maxZoomArt;
  const maxZoomHwy = module.maxZoomHwy;
  const isColorBlind = props.isColorBlind;

  useEffect(() => {
    if (map && !layerRenderer) {
      setLayerRender(L.canvas({ padding: 0.5 }));
    }
  }, [map, layerRenderer, setLayerRender])

  useEffect(() => {
    if (maxZoomArt) {
      if (zoom > maxZoomArt) {
        setZoomArtVisible(true)
      } else {
        setZoomArtVisible(false);
      }
    }
    if (maxZoomHwy) {
      if (zoom > maxZoomHwy) {
        setZoomHwyVisible(true)
      } else {
        setZoomHwyVisible(false);
      }
    }
  }, [zoom, maxZoomArt, maxZoomHwy])

  useEffect(() => {
    if (artVisible) {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext["Arterial Congestion"] = { ...newLayersContext["Arterial Congestion"], on: true, visible: zoomArtVisible };
        return newLayersContext;
      })
    } else {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext["Arterial Congestion"] = { ...newLayersContext["Arterial Congestion"], on: false, visible: zoomArtVisible };
        return newLayersContext;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artVisible, zoomArtVisible])

  useEffect(() => {
    if (hwyVisble) {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext["Highway Congestion"] = { ...newLayersContext["Highway Congestion"], on: true, visible: zoomHwyVisible };
        return newLayersContext;
      })
    } else {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext["Highway Congestion"] = { ...newLayersContext["Highway Congestion"], on: false, visible: zoomHwyVisible };
        return newLayersContext;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hwyVisble, zoomHwyVisible])

  useEffect(() => {
    if (undefined !== layersContext["Arterial Congestion"]) {
      var selected = layersContext["Arterial Congestion"].on;
      setArtVisible(selected);
    }
  }, [layersContext])

  useEffect(() => {
    if (undefined !== layersContext["Highway Congestion"]) {
      var selected = layersContext["Highway Congestion"].on;
      setHwyVisible(selected);
    }
  }, [layersContext])

  useEffect(() => {
    if (fullUpdate && !isUpdating) {
      setIsUpdating(true);
      setFullUpdate(false);
      setUpdate(false);
      getGeoLineFullUpdate(map.getBounds(), endpoint, setGeoJson, currentIds, setIsUpdating);
    }
    //eslint-disable-next-line 
  }, [fullUpdate, endpoint, isUpdating, setGeoJson, setFullUpdate, setUpdate, setIsUpdating])

  useEffect(() => {
    if (update && !fullUpdate && currentIds.current.length > 0 && !isUpdating) {
      setIsUpdating(true);
      setUpdate(false);
      getGeoLineColorUpdate(map.getBounds(), endpoint, setGeoJson, currentIds, setIsUpdating);
    }
    //eslint-disable-next-line 
  }, [update, endpoint, isUpdating, fullUpdate, setGeoJson, setUpdate, setIsUpdating])

  useEffect(() => {
    if (selectedFeature && geoJson && geoJson.features) {
      let newFeature = geoJson.features.find(feature => feature.properties.id === selectedFeature.properties.id);
      if (newFeature && !_.isEqual(selectedFeature.properties, newFeature.properties)) {
        setSelectedFeature(newFeature);
      }
    }
    if (geoJson) {
      currentIds.current = geoJson.features ? geoJson.features.map(feature => feature.properties.id) : [];
    }
  }, [geoJson, selectedFeature, setSelectedFeature])

  if (geoJson && geoJson.features && layerRenderer) {
    return (
      <>
        {artVisible && zoomArtVisible &&
          <FeatureGroup>
            {
              geoJson.features.map(feature => {
                return feature.properties.a ?
                  <Polyline positions={feature.geometry.coordinates} pathOptions={mystyle(feature, map, isColorBlind)} key={feature.properties.id} renderer={layerRenderer} eventHandlers={{
                    click: (e) => {
                      setClickLatLng(e.latlng);
                      setSelectedFeature(feature);
                      setCurrentModule(module);
                    }
                  }} /> : null
              })
            }
          </FeatureGroup>
        }
        {hwyVisble && zoomHwyVisible &&
          <FeatureGroup>
            {
              geoJson.features.map(feature => {
                //added condition to filter unknown congestion on the map
                return !feature.properties.a && feature.properties.cng!=='U'?
                  <Polyline positions={feature.geometry.coordinates} pathOptions={mystyle(feature, map, isColorBlind)} key={feature.properties.id} eventHandlers={{
                    click: (e) => {
                      setClickLatLng(e.latlng);
                      setSelectedFeature(feature);
                      setCurrentModule(module);
                    }
                  }} /> : null
              })
            }
          </FeatureGroup>
        }
      </>
    )
  } return null;

}

const getPointGeoJson = async (bounds, endpoint, setGeoJson, setUpdate, setLastUpdate, map) => {
  let boundsString = bounds.toBBoxString();
  let boundsArray = boundsString.split(",");
  for (let i = 0; i < boundsArray.length; i++) {
    boundsArray[i] = boundsArray[i] * 1;
  }
  if (endpoint === '/roadLabels.json') {
    let mercatorWidth = L.Projection.Mercator.project(bounds.getNorthEast()).x - L.Projection.Mercator.project(bounds.getNorthWest()).x;
    let zoom = map.getSize().x / mercatorWidth;
    const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'boundingBox': { 'bbox': boundsArray }, 'zoom': zoom })
    });
    res.json().then(res => {
      setGeoJson(res.features);
      setUpdate(false);
      setLastUpdate(new Date().getTime());
    })
  }
  else if (endpoint.includes("/transitEventMap.json") || endpoint === '/ferryMap.json') {
    const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
      method: 'GET',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      // body: JSON.stringify({ bbox: boundsArray })
    });
    res.json().then((geoJson) => {
      setGeoJson(geoJson.features);
      setUpdate(false);
      setLastUpdate(new Date().getTime());
    })
  }
  else {
    const res = await fetch(process.env.REACT_APP_API_HOST + endpoint, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ bbox: boundsArray })
    });
    res.json().then((geoJson) => {
      setGeoJson(geoJson.features);
      setUpdate(false);
      setLastUpdate(new Date().getTime());
    })
  }
}

const GeoJsonPointLayerOverlay = (props) => {
  const { module, setUpdate, setSelectedFeature, selectedFeature, update, setLayersOn, bounds, zoom, selectedItem, setSelectedItem } = props;
  const layersContext = React.useContext(LayersContext);
  const [geoJson, setGeoJson] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const geoJsonLayer = useRef(null);
  const title = module.name;
  const clusterLayer = module.clusterLayer ? module.clusterLayer : false;
  const endpoint = module.endpoint;
  const [visible, setVisible] = useState(undefined !== layersContext[title] && undefined !== layersContext[title].on ? layersContext[title].on : (undefined !== module.defaultOn ? module.defaultOn : true));
  const [zoomVisible, setZoomVisible] = useState(false);
  const [circle, setCircle] = useState(null);
  const maxZoom = module.maxZoom;

  useEffect(() => {
    if (maxZoom) {
      if (zoom > maxZoom) {
        setZoomVisible(true)
      } else {
        setZoomVisible(false);
      }
    }
  }, [zoom, maxZoom])

  useEffect(() => {
    if (visible && title) {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext[title] = { ...newLayersContext[title], on: true, visible: zoomVisible };
        return newLayersContext;
      })
    } else {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext[title] = { ...newLayersContext[title], on: false, visible: zoomVisible };
        return newLayersContext;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, visible, zoomVisible])

  useEffect(() => {
    if (undefined !== layersContext[title]) {
      const isSelected = layersContext[title].on;
      setVisible(isSelected);
      if (!isSelected) {
        if (selectedFeature) {
          console.log("geoJson static inside---------->" + JSON.stringify(geoJson))
          console.log("selectedFeature static inside---------->" + JSON.stringify(selectedFeature))
          var found = geoJson.find(feature => { if (feature.properties.id !== undefined && selectedFeature.properties.id !== undefined && feature.properties.id === selectedFeature.properties.id) return true });
          if (found) {
            setSelectedFeature(null);
          }
        }
      }
    }
  }, [layersContext, title, selectedFeature, setSelectedFeature, geoJson]);

  const map = useMap();

  useEffect(() => {
    if (null !== selectedItem && selectedItem.type === title) {
      if (!zoomVisible) {
        map.setZoom(maxZoom + 1);
      }
      var found = geoJson.find(feature => feature.properties.id === selectedItem.id);
      if (found) {
        console.log("found selected item: " + selectedItem.id)
        let coords = null;
        if (found.geometry.type === "Point") {
          coords = found.geometry.coordinates;

        } else if (found.geometry.type === "GeometryCollection") {
          coords = found.geometry.geometries[0].coordinates;
        }
        map.setView([coords[1], coords[0]], 18)
        setCircle(L.circle([coords[1], coords[0]], { radius: 10 }).addTo(map));
        setSelectedItem(null);
      }
    }
  }, [selectedItem, geoJson, setCircle, setSelectedItem, zoomVisible, map, maxZoom, title])

  useEffect(() => {
    if (null !== circle) {
      let zoom = map.getZoom();
      if (zoom < 19 && zoom >= 13) {
        if (zoom === 18) {
          circle.setRadius(10);
        } else if (zoom === 17) {
          circle.setRadius(15);
        } else if (zoom === 16) {
          circle.setRadius(40)
        } else if (zoom === 15) {
          circle.setRadius(100);
        } else if (zoom === 14) {
          circle.setRadius(200);
        } else if (zoom === 13) {
          circle.setRadius(275)
        }
      }
      else if (zoom < 13) {
        map.removeLayer(circle);
        setCircle(null);
        window.history.replaceState({}, "", "/");
      }
    }
  }, [zoom, circle, map])

  useEffect(() => {
    if (update && bounds && visible && zoomVisible) {
      getPointGeoJson(bounds, endpoint, setGeoJson, setUpdate, setLastUpdate, map);
    }
  }, [update, bounds, endpoint, setUpdate, visible, zoomVisible, map])

  useEffect(() => {
    if (selectedFeature && geoJson && geoJson.features) {
      let newFeature = geoJson.find(feature => feature.properties.id === selectedFeature.properties.id);
      if (newFeature && !_.isEqual(selectedFeature.properties, newFeature.properties)) {
        setSelectedFeature(newFeature);
      }
    }
  }, [geoJson, selectedFeature, setSelectedFeature])

  if (visible && zoomVisible) {
    if (clusterLayer) {
      return (
        <ClusterGeoJson geoJson={geoJson} {...props} key={geoJson} />
      )
    } else {
      return <GeoJsonLayer geoJson={geoJson} geoJsonLayer={geoJsonLayer} lastUpdate={lastUpdate} {...props} />
    }
  } else {
    return null;
  }
}


const GeoJsonLayer = (props) => {
  const { module, setSelectedFeature, setCurrentModule, geoJsonLayer, setClickLatLng, lastUpdate } = props;
  const geoJson = props.geoJson;
  const style = module.style;
  const getLeafletIcon = module.getLeafletIcon;
  const map = useMap();

  if (geoJson) {
    return (
      <GeoJSON data={geoJson}
        key={lastUpdate}
        ref={geoJsonLayer}
        style={style}
        onEachFeature={(feature, leafletLayer) => {
          leafletLayer.on('click', (e) => {
            setClickLatLng(e.latlng);
            setSelectedFeature(feature);
            setCurrentModule(module);
          })
        }}
        pointToLayer={undefined !== getLeafletIcon ? (feature, latlng) => { return getLeafletIcon(feature, latlng, map) } : () => { return null }}
      />
    )
  } else {
    return null;
  }

}

const StaticMarkerLayer = (props) => {
  const { module } = props;
  const geoJson = module.geoJson;
  const geoJsonLayer = useRef(null);
  const lastUpdate = new Date().getTime();

  const { setSelectedFeature, selectedFeature, setLayersOn } = props;
  const layersContext = React.useContext(LayersContext);
  const title = module.name;
  const [visible, setVisible] = useState(undefined !== layersContext[title] && undefined !== layersContext[title].on ? layersContext[title].on : (undefined !== module.defaultOn ? module.defaultOn : true));


  useEffect(() => {
    if (visible && title) {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext[title] = { ...newLayersContext[title], on: true, visible: true };
        return newLayersContext;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, visible])

  useEffect(() => {
    if (undefined !== layersContext[title]) {
      const isSelected = layersContext[title].on;
      setVisible(isSelected);
      if (!isSelected) {
        if (selectedFeature) {

          var found = geoJson.features.find(feature => { if (feature.properties.id !== undefined && selectedFeature.properties.id !== undefined && feature.properties.id === selectedFeature.properties.id) return true });
          if (found) {

            setSelectedFeature(null);
          }
        }
      }
    }
  }, [layersContext, title, selectedFeature, setSelectedFeature, geoJson]);

  if (visible && title) {
    return (
      <GeoJsonLayer geoJson={geoJson.features} geoJsonLayer={geoJsonLayer} lastUpdate={lastUpdate} {...props} />
    )
  }
}


const GeoJsonLayerOverlay = (props) => {
  const isColorBlind = React.useContext(ColorBlindContext);
  const [update, setUpdate] = useState(null);
  const [fullUpdate, setFullUpdate] = useState(false);
  /* eslint-disable no-unused-vars */
  const [lastUpdateTimer, setLastUpdateTimer] = useState(null);
  /* eslint-enable no-unused-vars */
  const [previousBounds, setPreviousBounds] = useState(null);
  const module = props.module;
  const updateDelay = module.updateDelay;
  const bounds = props.bounds;
  const congestionLayer = module.congestionLayer;

  useEffect(() => {
    const id = setInterval(() => {
      setUpdate(true);
    }, updateDelay * 1000);
    return () => clearInterval(id);
  }, [updateDelay]);

  useEffect(() => {
    if (bounds !== previousBounds && bounds !== null) {
      setPreviousBounds(bounds);
      setLastUpdateTimer(lastUpdateTimer => {
        if (null !== lastUpdateTimer) {
          clearTimeout(lastUpdateTimer)
        }
        return setTimeout(() => {
          setUpdate(true);
          setFullUpdate(true);
        }, 1000)
      });
    }
  }, [bounds, previousBounds])

  if (congestionLayer) {
    return <GeoJsonCongestionLayerOverlay {...props} setUpdate={setUpdate} update={update} bounds={bounds} previousBounds={previousBounds} fullUpdate={fullUpdate} setFullUpdate={setFullUpdate} isColorBlind={isColorBlind} />
  } else if (module.endpoint) {
    return <GeoJsonPointLayerOverlay {...props} setUpdate={setUpdate} update={update} />
  } else {
    return <StaticMarkerLayer {...props}></StaticMarkerLayer>
  }
}

const getLatLngCoords = (feature) => {
  if (feature.geometry.coordinates) {
    return new L.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
  }
  else if (feature.geometry.geometries) {
    return new L.LatLng(feature.geometry.geometries[0].coordinates[1], feature.geometry.geometries[0].coordinates[0])
  }
}

const ClusterGeoJson = (props) => {
  const [geoDataLayer, setGeoDataLayer] = useState(null);
  const map = useMap();
  const module = props.module;
  const markerClusterLayer = useRef(null);
  const geoData = props.geoJson;
  const setSelectedFeature = props.setSelectedFeature;
  const setCurrentModule = props.setCurrentModule;
  const getLeafletIcon = module.getLeafletIcon;
  const iconCreateFunction = module.iconCreateFunction;
  const clusterValue = module.clusterRadius ? module.clusterRadius : 10;

  /** 
   * This manually creates a geojson layer but does not add it to the 
   * map; it instead then adds the geojson layer to the MarkerClusterGroup.
   * There seems to be no "react" way to combine a geojson layer and marker
   * cluster 
   */
  useEffect(() => {
    markerClusterLayer.current.clearLayers();
    if (null === geoDataLayer) {
      setGeoDataLayer(L.geoJSON(geoData, {
        onEachFeature: (feature, leafletLayer) => {
          leafletLayer.on('click', () => {
            setSelectedFeature(feature);
            setCurrentModule(module);
          })
        },
        pointToLayer: (feature, latlng) => { return getLeafletIcon(feature, latlng, map) }
      }))
    } else {
      geoDataLayer.clearLayers().addData(geoData);
      markerClusterLayer.current.addLayer(geoDataLayer);
    }
  }, [geoData, geoDataLayer, getLeafletIcon, map, module, setCurrentModule, setSelectedFeature])
  if (iconCreateFunction) {
    return (
      <MarkerClusterGroup showCoverageOnHover={false} spiderfyOnMaxZoom={true} maxClusterRadius={clusterValue}
        ref={markerClusterLayer} iconCreateFunction={iconCreateFunction} title="Double-click to zoom" />
    )
  } else {
    return (
      <MarkerClusterGroup showCoverageOnHover={false} spiderfyOnMaxZoom={true} maxClusterRadius={clusterValue}
        ref={markerClusterLayer} title="Double-click to zoom" />
    )
  }
}


const GeoJsonPopup = (props) => {
  const isTabletOrMobileDevice = useMediaQuery('(max-device-width: 700px)');
  const selectedFeature = props.selectedFeature;
  const module = props.currentModule;
  const clickLatLng = props.clickLatLng;
  var popupPoint = null;
  const map = props.map;
  const usePopover = module.popOver;

  if (module && module.iconOffset) {
    popupPoint = module.iconOffset(selectedFeature, map)
  }

  if (module && (module.congestionLayer || module.polygonLayer)) {
    popupPoint = clickLatLng;
  }

  if (isTabletOrMobileDevice) {
    return (
      <DialogPopup {...props} selectedFeature={selectedFeature} module={module} />
    )
  } else {
    if (usePopover) {
      return (
        <DialogPopover {...props} selectedFeature={selectedFeature} module={module} />
      )
    }
    else if (popupPoint) {
      return (
        <MemorizedPopup selectedFeature={selectedFeature} {...props} position={popupPoint} module={module} />
      )
    } else {
      if (module.name !== "Shield")
        return (
          <MemorizedPopup selectedFeature={selectedFeature} {...props} position={getLatLngCoords(selectedFeature)} module={module} />
        )
    }
  }
}

const MemorizedPopup = (props) => {
  const module = props.currentModule;
  const selectedFeature = props.selectedFeature;
  const PopupTemplate = module.PopupTemplate;
  const setSelectedFeature = props.setSelectedFeature;
  const position = props.position;


  const memoPopup = useMemo(() => <Popup
    position={position}
    eventHandlers={{
      remove: () => {
        setSelectedFeature(null);
      },
    }}
    className="react-leaflet-popup"
  > <PopupTemplate feature={selectedFeature} module={module} /> </Popup>,
    [selectedFeature, module, position, setSelectedFeature]);

  return (
    <>
      {memoPopup}
    </>
  )
}

const DialogPopup = (props) => {
  const selectedFeature = props.selectedFeature;
  const [open, setOpen] = useState(null !== selectedFeature);
  const PopupTemplate = props.module.PopupTemplate;
  const setSelectedFeature = props.setSelectedFeature;
  const module = props.currentModule;

  const handleClose = () => {
    setOpen(false);
    setSelectedFeature(null);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="map-dialog" open={open}>
      <PopupTemplate feature={selectedFeature} module={module}></PopupTemplate>
    </Dialog>
  )
}

const DialogPopover = (props) => {
  const selectedFeature = props.selectedFeature;
  const [open, setOpen] = useState(null !== selectedFeature);
  const PopupTemplate = props.module.PopupTemplate;
  const setSelectedFeature = props.setSelectedFeature;
  const module = props.currentModule;

  const handleClose = () => {
    setOpen(false);
    setSelectedFeature(null);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="map-dialog" open={open}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <PopupTemplate feature={selectedFeature} module={module}></PopupTemplate>
    </Dialog>
  )
}

export default function MapDisplay(props) {
  const mapView = props.mapView;
  const mapId = props.mapId;
  var mapObj = mapId && mapId !== "" ? _.cloneDeep(mapsJson.find(mapObj => mapObj.id === mapId)) : _.cloneDeep(mapsJson[0]);
  if (!mapObj) mapObj = _.cloneDeep(mapsJson[0]);
  if (sessionStorage.getItem("mapZoom") !== null && sessionStorage.getItem("mapZoom") !== "")
    mapObj.zoom = sessionStorage.getItem("mapZoom")
  const lastMapId = getValueFromStorage(sessionStorage, "mapId");
  const [center, setCenter] = useStateWithLocalStorage("mapCenter", mapObj.center, mapId !== lastMapId ? mapObj.center : null);
  const [zoom, setZoom] = useStateWithLocalStorage("mapZoom", mapObj.zoom, mapId !== lastMapId ? mapObj.zoom : null);
  const [bounds, setBounds] = useState(null);
  const [map, setMap] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);
  const [clickLatLng, setClickLatLng] = useState(null);
  const selectedItem = props.selectedItem;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (mapView) {
      map.setView(mapView.position, mapView.zoom)
    }
    if (map) {
      setBounds(map.getBounds());
    }
  }, [mapView, map])

  useEffect(() => {
    var mapObj = mapId && mapId !== "" ? mapsJson.find(mapObj => mapObj.id === mapId) : mapsJson[0];
    // setZoom(mapObj.zoom);
    if (map) {
      // map.panTo(mapObj.center);
      // map.setZoom(mapObj.zoom);
      if (mapObj != null && mapObj.title === "Home") {
        if (sessionStorage.getItem("mapCenterLat") !== null && sessionStorage.getItem("mapCenterLng") !== null && sessionStorage.getItem("mapCenter") !== null) {
          setCenter([sessionStorage.getItem("mapCenterLat"), sessionStorage.getItem("mapCenterLng")])
          map.setCenter = sessionStorage.getItem("mapCenter")
          map.setView([sessionStorage.getItem("mapCenterLat"), sessionStorage.getItem("mapCenterLng")], sessionStorage.getItem("mapZoom"))
        }
      }
      else {
        map.panTo(mapObj.center);
        map.setZoom(mapObj.zoom);
        map.setView(mapObj.center, mapObj.zoom);
      }
    }
  }, [mapId, setZoom, map, setCenter])

  useEffect(() => {
    if (mapId !== lastMapId) {
      putValueToStorage(sessionStorage, "mapId", mapId);;
    }
  }, [mapId, lastMapId])

  var modulesForMap = useMemo(() => {
    var mapModules = [];
    for (var m in modules) {
      let module = modules[m];
      if (module.showOnMap) {
        mapModules.push(module)
      }
    } return mapModules;
  }, []);

  useEffect(() => {
    if (null != map) {
      // console.log("adding controls to map")
      // setZoom(sessionStorage.getItem("mapZoom"));
      // map.setZoom(sessionStorage.getItem("mapZoom"))
      map.on("moveend", e => {
        setCenter([map.getCenter().lat, map.getCenter().lng]);
        setBounds(map.getBounds());
        sessionStorage.setItem("mapZoom", map.getZoom())
        sessionStorage.setItem("mapCenter", map.getCenter())
        sessionStorage.setItem("mapCenterLat", map.getCenter().lat)
        sessionStorage.setItem("mapCenterLng", map.getCenter().lng)
        sessionStorage.setItem("mapBounds", map.getBounds())
        // console.log("moveend"+map.getCenter().lat , map.getCenter().lng)
        // console.log("Moveend Bounds:"+JSON.stringify(map.getBounds()))
      })
      map.on("zoomend", e => {
        // console.log("map zoom level is: " + map.getZoom());
        sessionStorage.setItem("mapZoom", map.getZoom())
        sessionStorage.setItem("mapCenter", map.getCenter())
        sessionStorage.setItem("mapCenterLat", map.getCenter().lat)
        sessionStorage.setItem("mapCenterLng", map.getCenter().lng)
        sessionStorage.setItem("mapBounds", map.getBounds())
        // console.log("last map zoom level is: " + sessionStorage.getItem("mapZoom"));
        setZoom(map.getZoom());
        setBounds(map.getBounds());
      })
      map.on("popupclose", e => {
        setSelectedFeature(null);
      })
    }
  }, [map, setCenter, setZoom]);

  const myPopup = useMemo(() => <GeoJsonPopup setSelectedFeature={setSelectedFeature} selectedFeature={selectedFeature} currentModule={currentModule} clickLatLng={clickLatLng} map={map} />,
    [selectedFeature, setSelectedFeature, currentModule, clickLatLng, map]);


  const displayMap =
    <MapContainer center={center} zoom={zoom} zoomControl={false} ref={setMap} >
      <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_KEY} mapId={prefersDarkMode ? process.env.REACT_APP_MAP_DARK : process.env.REACT_APP_MAP_LIGHT} minZoom={7} />
      {selectedFeature && <>{myPopup}</>}
      {map && modulesForMap.map(module => {
        return <GeoJsonLayerOverlay module={module} key={module.name} bounds={bounds} setSelectedFeature={setSelectedFeature} zoom={zoom}
          selectedFeature={selectedFeature} setCurrentModule={setCurrentModule} setClickLatLng={setClickLatLng} {...props} selectedItem={selectedItem} />
      })}
      <ZoomControl position="topright"></ZoomControl>
      <ResetControl position="topright"></ResetControl>
      <LocateControl position="topright" setView='untilPan' flyTo='true' keepCurrentZoomLevel='true' initialZoomLevel='15' />
      <Polygon positions={outlineJson[0]}>
        <OutsideCoveragePopup />
      </Polygon>
      <Polygon positions={outlineJson[1]}>
        <OutsideCoveragePopup />
      </Polygon>
      <Polygon positions={outlineJson[2]}>
        <OutsideCoveragePopup />
      </Polygon>
    </MapContainer>

  return (
    <>
      {displayMap}
    </>

  );
}

const OutsideCoveragePopup = (props) => {
  return (
    <Popup className="react-leaflet-popup" sx={{ m: 2 }}><Card sx={{ borderRadius: 3, p: 1 }}>This area is outside our coverage area.</Card></Popup>
  )
}

Control.Reset = Control.extend({
  onAdd: (map) => {
    var controlDiv = L.DomUtil.create('div', 'leaflet-control-zoom leaflet-bar leaflet-control');
    L.DomEvent
      .addListener(controlDiv, 'click', L.DomEvent.stopPropagation)
      .addListener(controlDiv, 'click', L.DomEvent.preventDefault)
      .addListener(controlDiv, 'click', function () {
        map.closePopup();
        let myUrl = window.location.href
        let lastMapId = sessionStorage.getItem("mapId");
        console.log(mapsJson);
        let myResetObj = _.cloneDeep(mapsJson[0]);
        if (myUrl.includes(lastMapId)) {
          myResetObj = mapsJson.find(map => map.id === lastMapId);
        }
        sessionStorage.removeItem("mapCenter")
        sessionStorage.removeItem("mapCenterLat")
        sessionStorage.removeItem("mapCenterLng")
        sessionStorage.removeItem("mapZoom")
        sessionStorage.setItem("mapZoom", myResetObj.zoom)
        map.setView(myResetObj.center, myResetObj.zoom);
        //map.setBounds(map.getBounds());
      });
    var controlUI = L.DomUtil.create('a', 'reset-control material-icons', controlDiv);
    controlUI.innerText = "restart_alt";
    controlUI.title = 'Reset Map';
    return controlDiv;
  }
})

const ResetControl = createControlComponent(
  (props) => new Control.Reset(props)
);

const { Locate } = L.Control;
const createLocateInstance = (props) => {
  const instance = new Locate(props);
  return instance;
}

const LocateControl = createControlComponent(createLocateInstance);

/* The below function can be used to add a zoom display to the main map
for testing purposes
const ZoomDisplayControl = createControlComponent(
  (props) => new Control.ZoomDisplay(props)
);

Control.ZoomDisplay = Control.extend({
  onAdd: (map) => {
    var controlDiv = L.DomUtil.create('div', 'leaflet-display-zoom leaflet-bar leaflet-control');
    controlDiv.id = "zoom_display";
    controlDiv.title = "Zoom Level";
    controlDiv.innerText = map.getZoom();
    map.on("zoomend", e => {
      let control = document.getElementsByClassName("leaflet-display-zoom")[0];
      control.innerText = e.target.getZoom();
    })
    return controlDiv;
  }
})*/

