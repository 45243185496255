import React from 'react';
import Report from '../../../components/Report';
import { Description } from '../../../util/ReportUtil';
import Avatar from '@mui/material/Avatar';
import { titleCase } from 'title-case';
import { Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import { copyText } from '../../../util/ReportUtil';

const endpoint = "/specialEvents.json";

const SpecialEventsReport = (props) => {
    return (
        <Report {...props} />
    )
}

const EventLocation = (props) => {
    const [searchParams] = useSearchParams();
    const [isDebug, setDebug] = React.useState(false);
    const html = props.value;
    const d = props.d;

    React.useEffect(() => {
        const debug = searchParams.get('debug');
        if (null !== debug) {
            setDebug(true)
        } else {
            setDebug(false);
        }
    }, [searchParams])

    const url = `/map?lat=${d.latitude}&lng=${d.longitude}&type=`
    return (
        <div title={d.fullLocation}>
            <Link to={url} target="_blank" className="reportCell"><span dangerouslySetInnerHTML={{ __html: html }} /></Link>
            <LinkIcon fontSize="small" className="link-icon" />
            {isDebug &&
                <div title="Click to Copy">
                    <Typography variant='body2' className="hover-highlight" onClick={() => copyText(d.id.trim())}><span>{d.id.trim()}</span></Typography>
                </div>
            }
        </div>
    )
}

const tableColumns = [
    { field: 'road', numeric: false, label: 'Primary', excludeSort: false, compType: "interstate" },
    { field: 'description', numeric: false, label: 'Description', excludeSort: false, CellTemplate: Description },
    { field: 'location', numeric: false, label: 'Location', CellTemplate: EventLocation },
    { field: 'mileMarker', numeric: false, label: 'Mile Marker' },
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'status', numeric: false, label: 'Status' },
    { field: 'startTime', numeric: false, label: 'Start Time' },
    { field: 'estimatedEndTime', numeric: false, label: 'Estimated End Time' },
    { field: 'source', numeric: false, label: 'Source' }
];

const cardColumns = [
    { field: 'closureDetails', numeric: false, label: 'Closure Details' },
    { field: 'status', numeric: false, label: 'Status' },
    { field: 'startTime', numeric: false, label: 'Start Time' },
    { field: 'estimatedEndTIme', numeric: false, label: 'Estimated End Time' },
    { field: 'source', numeric: false, label: 'Source' }
];

const MyCardHeader = (props) => {
    const d = props.d
    return (
        titleCase(d.description) + " : " + d.road
    )
}

const MyCardSubHeader = (props) => {
    const d = props.d
    return (
        <EventLocation value={d.location} d={d}/>
    )
}

const MyAvatar = (props) => {
    return (
        <Avatar
            alt="Special Events"
            src="/mapicons/SpecialEvents/Special_Event_v14.png"
            aria-label="Incident"
            sx={{ width: 21, height: 21 }} >
        </Avatar>
    )
}

const DescriptionBlock = (props) => {
    return (
        <Box sx={{ pl: 2, mb: 1 }}>
            <Typography variant="body1">Hover over a location to see its municipality and county, or click on the location to see the map of the event.</Typography>
        </Box>
    )
}

const module = {
    routeProps: {
        path: 'SpecialEventsReport',
        component: SpecialEventsReport
    },
    name: 'Special Events',
    label: "Special Events",
    title: "Special Events Report",
    showOnMenu: true,
    endpoint: endpoint,
    tableColumns: tableColumns,
    cardColumns: cardColumns,
    tabularFormat: true,
    orderBy: null,
    cardHeader: MyCardHeader,
    cardSubHeader: MyCardSubHeader,
    cardAvatar: MyAvatar,
    descriptionBlock: DescriptionBlock
}

export default module;